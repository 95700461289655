import i18next from "i18next";
import { Link } from "react-router-dom";
import { allAirlines } from "../../../../../info/airlinesInfo/allAirlines";
import Card from "../../../../ui/Card";
import { Cards, Title, Wrapper } from "./style";

export const PartnersListComponent = () => {
  const { t, language } = i18next;

  const partner = allAirlines.filter(item => item.partner === true);

  return (
    <Wrapper>
      <Title>{t("partners")}</Title>

      <Cards>
        {partner.map((item, index) => {
          const destination = {
            pathname: `/airlines/${item.name}`,
            state: { ...item.name },
          };
          return (
            <Link key={index} to={destination}>
              <Card imageSrc={item.imageUrl} title={item.listName[language].name} />
            </Link>
          );
        })}
      </Cards>
    </Wrapper>
  );
};

import i18next from "i18next";
import {
  About,
  IataLogo,
  LegalInformation,
  LinkItem,
  LinksBlock,
  LogosBlock,
  Opportunities,
  PaymentsLogo,
  Socials,
  SocialsLink,
  TkpLogo,
  Wrapper,
} from "./style";

const FooterTopComponent = () => {
  const { t } = i18next;

  return (
    <Wrapper>
      <LinksBlock>
        <LegalInformation>
          <h2>{t("legal_information")}</h2>
          <div>
            <LinkItem to="/rules-of-use-info">{t("rules_of_use")}</LinkItem>
            <LinkItem to="/public-offer-info">{t("offer")}</LinkItem>
            <LinkItem to="/privacy-policy-info">{t("privacy_policy")}</LinkItem>
            <LinkItem to="/payment-info">{t("payment")}</LinkItem>
          </div>
        </LegalInformation>

        <Opportunities>
          <h2>{t("opportunities")}</h2>
          <LinkItem to="/airlines">{t("airlines")}</LinkItem>
          <LinkItem to="/partners">{t("partners")}</LinkItem>
        </Opportunities>

        <About>
          <h2>{t("wannatalk")}</h2>
          <div>
            <LinkItem href="#">{t("about_company")}</LinkItem>
            <LinkItem to="/contacts">{t("contact_information")}</LinkItem>
          </div>
          <SocialsLink>
            <LinkItem to="https://www.instagram.com/biletbor.me?igsh=MW9xN2x4eXZnYzIzdg==" target="_blank">
              <Socials src="/socials/instagram-white.svg" alt="Instagram" />
            </LinkItem>
            <LinkItem to="https://t.me/biletBorme" target="_blank">
              <Socials src="/socials/telegram-white.svg" alt="Telegram" />
            </LinkItem>
          </SocialsLink>
        </About>
      </LinksBlock>

      <LogosBlock>
        <div>
          <PaymentsLogo src="/images/payment-logos.png" alt="payment-logos" />
        </div>

        <div>
          <IataLogo src="/images/iata-logo.png" alt="iata-logo" />
          <TkpLogo src="/images/tkp-logo.png" alt="tkp-logo" />
        </div>
      </LogosBlock>
    </Wrapper>
  );
};

export default FooterTopComponent;

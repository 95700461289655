import i18next from "i18next";
import { useState } from "react";
import { PartnersSwiperComponent } from "../info/PartnersComponent/PartnersSwiperComponent/PartnersSwiperComponent";
import { ContainerComponent, SelectComponent } from "../ui";
import { Title, Wrapper } from "./Home.style";

const Home = () => {
  const [airport, setAirport] = useState("9600397");
  const { t } = i18next;

  return (
    <ContainerComponent fixed style={{ width: "100%" }}>
      <Wrapper>
        <PartnersSwiperComponent />
      </Wrapper>
    </ContainerComponent>
  );
};

export default Home;

import React from "react";

import i18next from "i18next";
import { CardDesc, Img, Title, Wrapper } from "./style";

export default function Card({ imageSrc, title }) {
  const { t } = i18next;

  return (
    <Wrapper>
      <Img>
        <img src={imageSrc} alt="logo" />
      </Img>
      <CardDesc>
        <Title>
          <h2>{t(`${title}`)}</h2>
        </Title>
      </CardDesc>
    </Wrapper>
  );
}

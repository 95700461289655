import { LogoLink, Wrapper } from "./style";

const HeaderLogoComponent = () => {
	return (
		<Wrapper>
			<LogoLink href="/">
				<img src="/images/logo.png" alt="logo" />
			</LogoLink>
		</Wrapper>
	)
};

export default HeaderLogoComponent;

const metaTitles = {
  ru: {
    homePage: "Онлайн-касса BiletBor.me",
    aeroflotInfo: "Аэрофлот: обзор авиакомпании, aviabilet и направления из Узбекистана",
    airastanaInfo: "Air Astana: обзор авиакомпании, aviabilet и направления из Узбекистана",
    aircairoInfo: "Air Cairo: обзор авиакомпании, aviabilet и направления из Узбекистана",
    airchinaInfo: "Air China: обзор авиакомпании, aviabilet и направления из Узбекистана",
    airmanasInfo: "Air Manas: обзор авиакомпании, aviabilet и направления из Узбекистана",
    asianaAirlinesInfo: "Asiana Airlines: обзор авиакомпании, aviabilet и направления из Узбекистана",
    aviaTrafficInfo: "Avia Traffic Company: обзор авиакомпании, aviabilet и направления из Узбекистана",
    azalInfo: "AZAL (Azerbaijan Airlines): обзор авиакомпании, aviabilet и направления из Узбекистана",
    azimuthInfo: "Azimuth: обзор авиакомпании, aviabilet и направления из Узбекистана",
    azurairInfo: "Azur Air: обзор авиакомпании, aviabilet и направления из Узбекистана",
    belaviaInfo: "Belavia: обзор авиакомпании, aviabilet и направления из Узбекистана",
    uzairwaysInfo: "O'zbekiston Havo Yo'llari – chiptalar, jadval, samolyot parki, yo'nalishlar",
    s7Info: "S7 Airlines – авиабилеты, флот, расписание рейсов из Узбекистана",
    turkishInfo: "Turkish Airlines – авиабилеты, флот, расписание рейсов из Узбекистана",
    qanotsharqInfo: "Qanot Sharq – авиакомпания Узбекистана, маршруты, билеты",
    uralInfo: "Ural Airlines – авиабилеты, флот, расписание рейсов из Узбекистана",
  },
  en: {
    homePage: "Online ticketing BiletBor.me",
    aeroflotInfo: "Аэрофлот: обзор авиакомпании, aviabilet и направления из Узбекистана",
  },
  uz: {
    homePage: "Onlayn kassa BiletBor.me",
    aeroflotInfo: "Аэрофлот: обзор авиакомпании, aviabilet и направления из Узбекистана",
    uzairwaysInfo: "O'zbekiston Havo Yo'llari – chiptalar, jadval, samolyot parki, yo'nalishlar",
    s7Info: "S7 Airlines – aviabiletlar, yo‘nalishlar, O‘zbekistondan reyslar",
    turkishInfo: "Turkish Airlines – aviabiletlar, yo‘nalishlar, O‘zbekistondan reyslar",
    qanotsharqInfo: "Qanot Sharq – O‘zbekiston aviakompaniyasi, reyslar va chipta narxlari",
    uralInfo: "Ural Airlines – aviabiletlar, yo‘nalishlar, O‘zbekistondan reyslar",
  },
};

export default metaTitles;

export const info = {
  ru: {
    aeroflot: {
      pageKey: "aeroflotInfo",
      imageUrl: "/images/AK/SU.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Аэрофлот</h1>
            <h2>Дата основания и история</h2>
            <p>Аэрофлот (ПАО «Аэрофлот — Российские авиалинии») — одна из старейших авиакомпаний мира,
          основанная в 1923 году. За свою долгую историю прошла путь от монополиста в советский
          период до ведущего международного перевозчика, предлагающего авиабилеты (aviabilet) по
          множеству направлений, включая Узбекистан.</p>
            <h3>Флот Аэрофлота</h3>
            <p>В парке Аэрофлота преимущественно современные самолёты Airbus и Boeing. Линейка включает
            коротко- и среднемагистральные Airbus A320/321, а также дальнемагистральные Boeing 777 и 
            Airbus A330.</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~100</td>
                  <td>Коротко- и среднемагистральные маршруты</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~20</td>
                  <td>Дальние рейсы, повышенная комфортность</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~20</td>
                  <td>Межконтинентальные направления, просторные салоны</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Аэрофлот осуществляет прямые рейсы из следующих городов Узбекистана в Москву (Шереметьево):</p>
            <ul>
              <li>
                <b>Ташкент (TAS): </b>обычно ежедневно (7 раз в неделю).
              </li>
              <li>
                <b>Самарканд (SKD): </b>2–3 раза в неделю.
              </li>
              <li>
                <b>Бухара (BHK): </b>1–2 раза в неделю.
              </li>
            </ul>
            <p>Уточняйте авиабилеты и актуальные дни вылета на официальном сайте или 
          <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a>.</p>
          <h3>Короткий вывод</h3>
          <p>Аэрофлот — это сочетание многолетнего опыта, широкой маршрутной сети и современного авиапарка. 
          Если вам нужны авиабилеты (aviabilet) в Москву или другие города, обязательно сравните цены 
          и воспользуйтесь акциями на biletbor.me или на официальном сайте.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Россия</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Москва</p> 
            <h4>Основной хаб: </h4>   
            <p>Аэропорт Шереметьево (терминалы B/C), откуда выполняется большинство международных и региональных рейсов</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.aeroflot.ru" target="_blank" rel="noreferrer">www.aeroflot.ru</a>
          `,
        },
      ],
    },
    airastana: {
      name: "airastana",
      pageKey: "airastanaInfo",
      imageUrl: "/images/AK/KC.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Astana</h1>
            <h2>Дата основания и история</h2>
            <p>Air Astana — национальная авиакомпания Республики Казахстан, основанная в 2001 году. С момента запуска она активно развивает маршрутную сеть, предлагая aviabilet дешевые и премиальные тарифы. Компания постоянно совершенствует сервис на борту и качество обслуживания.</p>
            <h3>Флот Air Astana</h3>
            <p>Air Astana эксплуатирует современные самолёты семейств Airbus (A320, A321, A321LR/neo), Boeing (757, 767) и Embraer (E190-E2). Такой флот обеспечивает гибкость в выборе маршрутов и комфорт для пассажиров, ищущих как aviabilet narxlari эконом-класса, так и более просторные места бизнес-класса.</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320 / A321 / A321LR</td>
                  <td>Современные модели для ближне- и среднемагистральных рейсов</td>
                </tr>
                <tr>
                  <td>Boeing 757 / 767</td>
                  <td>Надёжные дальнемагистральные лайнеры</td>
                </tr>
                <tr>
                  <td>Embraer E190-E2</td>
                  <td>Удобные региональные самолёты для коротких перелётов</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Air Astana предлагает прямые и стыковочные рейсы из:</p>
            <ul>
              <li>
                <b>Ташкента (TAS):</b> в Алматы или Астану (несколько раз в неделю)
              </li>
              <li>
                <b>Самарканд (SKD): </b>периодически сезонные рейсы (уточняйте на biletbor.me)
              </li>
              <li>
                <b>Бухара (BHK): </b>1–2 раза в неделю.
              </li>
            </ul>
            <p>Далее возможны пересадки на другие маршруты, включая aviabilet moskva, aviabilet sankt peterburg и другие направления. Актуальное расписание смотрите на  
          <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a>.</p>
            <h3>Короткий вывод</h3>
            <p>Air Astana — динамично развивающаяся авиакомпания Казахстана, предлагающая удобные рейсы между Узбекистаном и многими городами мира. Если вы ищете комфорт и доступные цены, стоит обратить внимание на их aviabilet narxlari и стыковочные возможности для дальнейших перелётов.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Казахстан</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Алматы</p> 
            <h4>Основной хаб: </h4>   
            <p>Международные аэропорты Алматы и Астаны
              Air Astana осуществляет рейсы в страны СНГ, Азии, Ближнего Востока и Европы, давая пассажирам возможность сравнивать aviabilet moskva и aviabilet sankt peterburg с другими направлениями
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.airastana.com" target="_blank" rel="noreferrer">www.airastana.com</a>
          `,
        },
      ],
    },
    aircairo: {
      name: "aircairo",
      pageKey: "aircairoInfo",
      imageUrl: "/images/AK/SM.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Cairo</h1>
            <h2>Дата основания и история</h2>
            <p>Air Cairo — египетская авиакомпания, основанная в 2003 году. Первоначально компания развивалась как чартерный перевозчик, ориентированный на туристические направления, но впоследствии стала выполнять и регулярные рейсы. Air Cairo предлагает aviabilet дешевые направления, включая вылеты из Узбекистана.</p>
            <h3>Флот Air Cairo</h3>
            <p>Air Cairo использует современные самолёты семейства Airbus A320/321. Флот ориентирован на средние дистанции и обеспечивает комфорт в салонах экономического класса и отдельных бизнес-направлениях. При выборе aviabilet narxlari можно найти как базовые, так и улучшенные тарифы.</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>Среднемагистральные рейсы, базовый эконом и отдельные бизнес-тарифы</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>Увеличенная пассажировместимость для популярных направлений</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Air Cairo периодически предлагает рейсы из Узбекистана в Египет (Каир, Шарм-эш-Шейх и т.д.) в зависимости от туристического сезона и спроса.</p>
            <ul>
              <li>
                <b>Ташкента (TAS):</b> может выполняться в туристический сезон (уточняйте на <a href="https://biletbor.me">biletbor.me)</a>.
              </li>
              <li>
                <b>Другие города: </b>возможны чартеры в пиковые даты.
              </li>
            </ul>
            <p>Далее возможны пересадки на другие маршруты, включая aviabilet moskva, aviabilet sankt peterburg и другие направления. Актуальное расписание смотрите на  
          <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a>.</p>
            <h3>Короткий вывод</h3>
            <p>Air Cairo — удобный выбор для путешествий между Узбекистаном и Египтом, особенно в туристический сезон. Авиакомпания сочетает доступные aviabilet narxlari с регулярными спецпредложениями, что может быть интересно как любителям пляжного отдыха, так и тем, кто ищет новые маршруты.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Египет</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Каир</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Каир (CAI)
               Авиакомпания известна рейсами в Ближний Восток, Европу и некоторые города в Азии, что даёт возможность сравнить aviabilet moskva и aviabilet sankt peterburg при планировании стыковок.
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.aircairo.com" target="_blank" rel="noreferrer">www.aircairo.com</a>
          `,
        },
      ],
    },
    airchina: {
      name: "airchina",
      pageKey: "airchinaInfo",
      imageUrl: "/images/AK/CA.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air China</h1>
            <h2>Дата основания и история</h2>
            <p>Air China — национальный авиаперевозчик Китая, ведущий свою историю с 1988 года, когда из нескольких региональных компаний был сформирован единый бренд. Сегодня Air China считается одной из крупнейших авиакомпаний в Азии, предлагая aviabilet дешевые и премиум-класса на различные направления по всему миру.</p>
            <h3>Флот Air China</h3>
            <p>Air China эксплуатирует широкий спектр самолётов, включая Boeing 737, 777, 787, а также Airbus A320, A330, A350. Разнообразие парка позволяет обеспечивать дальнемагистральные и региональные рейсы, сохраняя высокие стандарты безопасности и сервиса. При покупке aviabilet narxlari можно выбрать различные классы обслуживания (эконом, бизнес, первый класс).</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>Среднемагистральные рейсы, базовый эконом и отдельные бизнес-тарифы</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>Увеличенная пассажировместимость для популярных направлений</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Air China периодически предлагает прямые и стыковочные рейсы из:</p>
            <ul>
              <li>
                <b>Ташкента (TAS):</b> как правило, с пересадкой в Пекине или другом хабе (расписание уточняйте на <a href="https://biletbor.me">biletbor.me</a>).
              </li>
              <li>
                <b>Другие города Узбекистана: </b>могут быть сезонные или чартерные программы по мере роста спроса.
              </li>
            </ul>
            <h3>Короткий вывод</h3>
            <p>Air China — динамично развивающаяся авиакомпания с широкой маршрутной сетью и комфортным сервисом. Она отлично подойдёт тем, кто планирует путешествия из Узбекистана в Китай или другие страны. Сравнивайте aviabilet narxlari и выбирайте оптимальный вариант на <a href="https://biletbor.me">biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Китай</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Пекин</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Пекин Столичный (PEK)
               Также авиакомпания использует аэропорты Чэнду, Шанхая и других крупных городов в качестве дополнительных хабов. Пассажиры могут подобрать aviabilet moskva, aviabilet sankt peterburg или другие маршруты с удобными стыковками.
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.aircairo.com" target="_blank" rel="noreferrer">www.aircairo.com</a>
          `,
        },
      ],
    },
    airmanas: {
      name: "airmanas",
      pageKey: "airmanasInfo",
      imageUrl: "/images/AK/ZM.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Manas</h1>
            <h2>Дата основания и история</h2>
            <p>Air Manas — киргизская авиакомпания, начавшая свою деятельность в 2006 году. С момента основания компания развивала сеть внутриреспубликанских и международных рейсов, стремясь предлагать aviabilet дешевые и гибкие условия перелётов.</p>
            <h3>Флот Air China</h3>
            <p>Флот Air Manas обычно включает несколько самолётов Boeing 737 различных модификаций. Эти воздушные суда хорошо подходят для кратко- и среднемагистральных маршрутов, обеспечивая пассажирам доступные aviabilet narxlari и комфортное путешествие.</p>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Air Manas периодически открывает и обновляет рейсы из Узбекистана, особенно из:</p>
            <ul>
              <li>
                <b>Ташкента (TAS):</b> до Бишкека и в обратном направлении (график зависит от сезона и спроса)..
              </li>
              <li>
                <b>Другие города: </b>могут предлагаться сезонно или в формате чартерных программ.
              </li>
            </ul>
            <h3>Короткий вывод</h3>
            <p>Air Manas — динамично развивающаяся киргизская авиакомпания, которая может стать удобным вариантом для перелётов между Узбекистаном и Кыргызстаном, а также для стыковок в другие города. Сравнивайте aviabilet narxlari на <a href="https://biletbor.me">biletbor.me</a> и выбирайте наиболее подходящий рейс.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Кыргызстан</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Бишкек</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Манас (FRU)
               Air Manas выполняет рейсы в страны СНГ и ближайшие регионы, что даёт возможность сравнивать aviabilet moskva или aviabilet sankt peterburg при планировании дальних перелётов.
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.airmanas.com" target="_blank" rel="noreferrer">www.airmanas.com</a>
          `,
        },
      ],
    },
    asianaAirlines: {
      name: "asianaAirlines",
      pageKey: "asianaAirlinesInfo",
      imageUrl: "/images/AK/OZ.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Asiana Airlines</h1>
        <h2>Дата основания и история</h2>
        <p>Asiana Airlines — крупная авиакомпания Республики Корея, основанная в 1988 году. За время своей работы компания получила широкое признание за высокий уровень сервиса и безопасности, предлагая aviabilet дешевые и премиальные классы на разнообразные направления, включая рейсы в Узбекистан.</p>
        <h3>Флот Asiana Airlines</h3>
        <p>В парк Asiana Airlines входят современные самолёты Airbus (A320, A350) и Boeing (B747, B777). Подобное разнообразие позволяет обслуживать как короткие региональные маршруты, так и дальнемагистральные рейсы. При выборе aviabilet narxlari можно найти билеты в эконом-, бизнес- или первом классе.</p>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Asiana Airlines периодически предлагает прямые или стыковочные рейсы из:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> с пересадкой в Сеуле (в разное время года, в зависимости от сезона).
          </li>
          <li>
            <b>Другие города:</b> возможны чартерные или сезонные рейсы при повышенном туристическом спросе.
          </li>
        </ul>
        <h3>Короткий вывод</h3>
        <p>Asiana Airlines — подходящий выбор для тех, кто ценит комфорт и безопасность. Если вы планируете полёт из Узбекистана в Сеул или далее, сравните aviabilet narxlari и выбирайте удобный класс обслуживания. Подробности и акции ищите на <a href="https://biletbor.me">biletbor.me</a>.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Республика Корея</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Сеул</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Инчхон (ICN). Asiana Airlines также имеет важные маршруты из аэропорта Гимпо и других корейских городов, что позволяет пассажирам выбрать удобные aviabilet moskva или aviabilet sankt peterburg при планировании дальних перелётов.</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.flyasiana.com" target="_blank" rel="noreferrer">www.flyasiana.com</a>
      `,
        },
      ],
    },
    aviaTraffic: {
      name: "aviaTraffic",
      pageKey: "aviaTrafficInfo",
      imageUrl: "/images/AK/AT.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Avia Traffic</h1>
        <h2>Дата основания и история</h2>
        <p>Avia Traffic Company — киргизская авиакомпания, ведущая свою деятельность с 2003 года. Она постепенно расширяла маршрутную сеть, предлагая aviabilet дешевые и различные сервисные опции, ориентируясь на удобство и безопасность пассажиров.</p>
        <h3>Флот Avia Traffic</h3>
        <p>В распоряжении Avia Traffic Company обычно несколько самолётов Boeing 737 и Airbus A320:</p>
        <table>
          <thead>
            <tr>
              <th>Самолёт</th>
              <th>Особенности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Boeing 737</td>
              <td>Идеален для коротко- и среднемагистральных маршрутов</td>
            </tr>
            <tr>
              <td>Airbus A320</td>
              <td>Удобный салон, варианты эконом и бизнес-класса</td>
            </tr>
          </tbody>
        </table>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Avia Traffic Company предлагает рейсы из:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> В Бишкек (и обратно), частота зависит от сезона.
          </li>
          <li>
            <b>Другие города Узбекистана:</b> Возможны чартеры или дополнительные рейсы в пиковые периоды.
          </li>
        </ul>
        <h3>Короткий вывод</h3>
        <p>Avia Traffic Company — удобный вариант для перелётов между Кыргызстаном и Узбекистаном. Если вы ищете доступные aviabilet narxlari и приемлемые условия полёта, обязательно обратите внимание на рейсы этой авиакомпании, проверив доступные варианты на <a href="https://biletbor.me">biletbor.me</a>.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Республика Корея</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Сеул</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Инчхон (ICN). Asiana Airlines также имеет важные маршруты из аэропорта Гимпо и других корейских городов, что позволяет пассажирам выбрать удобные aviabilet moskva или aviabilet sankt peterburg при планировании дальних перелётов.</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.aero.kg/" target="_blank" rel="noreferrer">www.aviatraffic.com</a>
      `,
        },
      ],
    },
    azal: {
      name: "azal",
      pageKey: "azalInfo",
      imageUrl: "/images/AK/J2.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Azal</h1>
        <h2>Дата основания и история</h2>
        <p>AZAL (Azerbaijan Airlines) — национальный авиаперевозчик Азербайджана, основанный в 1992 году на базе государственной авиационной структуры. С тех пор авиакомпания активно развивается, предлагая пассажирам aviabilet дешевые и премиальные тарифы на разных направлениях.</p>
        <h3>Флот Azal</h3>
        <p>Во флоте AZAL в основном самолёты семейства Airbus (A319, A320, A321) и Boeing (757, 767, 787). Такой состав позволяет обслуживать как ближнемагистральные, так и дальние направления, обеспечивая пассажирам комфорт и разнообразие aviabilet narxlari.</p>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>AZAL периодически выполняет прямые и стыковочные рейсы:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> рейсы в Баку (частота и дни вылета зависят от сезона).
          </li>
          <li>
            <b>Другие города Узбекистана:</b> могут быть чартерные или сезонные рейсы по мере увеличения спроса.
          </li>
        </ul>
        <h3>Короткий вывод</h3>
        <p>AZAL (Azerbaijan Airlines) — удобный выбор для перелётов между Узбекистаном и Азербайджаном, а также для дальних рейсов с пересадкой в Баку. Сравните aviabilet narxlari и найдите подходящие варианты на <a href="https://biletbor.me">biletbor.me</a>.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Азербайджан</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Азербайджан</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Гейдар Алиев (GYD)
           AZAL выполняет рейсы во многие города Европы, Азии и СНГ, что позволяет сравнивать aviabilet moskva или aviabilet sankt peterburg, а также планировать стыковки при полётах из Узбекистана.</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.azal.az/" target="_blank" rel="noreferrer">www.azal.az</a>
      `,
        },
      ],
    },
    azimuth: {
      name: "azimuth",
      pageKey: "azimuthInfo",
      imageUrl: "/images/AK/A4.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Azimuth</h1>
        <h2>Дата основания и история</h2>
        <p>Azimuth — российская авиакомпания, основанная в 1993 году. С момента своего основания компания специализируется на региональных перевозках, предоставляя пассажирам aviabilet дешевые и удобные условия для путешествий по России и соседним странам.</p>
        <h3>Флот Azal</h3>
        <p>Флот Azimuth состоит из современных самолётов Embraer ERJ-145 и ATR 72. Эти воздушные суда идеально подходят для коротко- и среднемагистральных маршрутов, обеспечивая пассажирам комфорт и безопасность. При выборе aviabilet narxlari можно найти как эконом, так и бизнес-классы.</p>
        <table>
          <thead>
            <tr>
              <th>Самолёт</th>
              <th>Особенности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Embraer ERJ-145</td>
              <td>Идеален для региональных и средне-магистральных маршрутов</td>
            </tr>
            <tr>
              <td>ATR 72</td>
              <td>Экономичные самолёты для коротких рейсов</td>
            </tr>
          </tbody>
        </table>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Azimuth предлагает рейсы из следующих городов Узбекистана:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> рейсы в Москву с пересадкой в Домодедово (частота зависит от сезона).
          </li>
          <li>
            <b>Самарканд (SKD):</b> сезонные рейсы в Москву и другие направления.
          </li>
        </ul>
        <h3>Короткий вывод</h3>
        <p>Azimuth — надёжная российская авиакомпания, предоставляющая доступные aviabilet narxlari и комфортные условия для пассажиров. Если вы планируете путешествие из Узбекистана в Россию или другие страны СНГ, сравните предложения Azimuth на <a href="https://biletbor.me">biletbor.me</a> и выберите наиболее подходящий рейс.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Россия</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Москва</p> 
        <h4>Основной хаб:</h4>   
        <p>Аэропорт Домодедово (DME)
           Azimuth выполняет рейсы в различные регионы России, а также в страны СНГ. Это позволяет пассажирам выбирать aviabilet moskva, aviabilet sankt peterburg и другие направления с удобными пересадками.</p>
        <h4>Вебсайт:</h4>
        <a href="https://azimuth.ru" target="_blank" rel="noreferrer">www.azimuth.ru</a>
      `,
        },
      ],
    },
    azurair: {
      name: "azurair",
      pageKey: "azurairInfo",
      imageUrl: "/images/AK/ZF.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Azur Air</h1>
        <h2>Дата основания и история</h2>
        <p>Azur Air — российская авиакомпания, основанная в 2006 году. С момента своего основания компания специализировалась на чартерных рейсах, обслуживая туристические направления. В последние годы Azur Air расширила свой маршрутный график, предлагая регулярные рейсы и новые направления, что позволяет пассажирам воспользоваться aviabilet дешевые и удобными условиями полёта.</p>
        <h3>Флот Azal</h3>
        <p>Флот Azur Air включает современные самолёты Airbus A320 и Boeing 737. Эти воздушные суда обеспечивают комфорт и безопасность пассажиров на коротко- и среднемагистральных маршрутах. Разнообразие флота позволяет Azur Air предлагать различные aviabilet narxlari в зависимости от класса обслуживания (эконом, бизнес).</p>
        <table>
          <thead>
            <tr>
              <th>Самолёт</th>
              <th>Особенности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Airbus A320</td>
              <td>Комфортные условия для эконом и бизнес-класса</td>
            </tr>
            <tr>
              <td>Boeing 737</td>
              <td>Надёжность и безопасность для коротко- и среднемагистральных маршрутов</td>
            </tr>
          </tbody>
        </table>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Azur Air предлагает рейсы из следующих городов Узбекистана:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> рейсы в Москву с пересадкой в Домодедово (частота зависит от сезона).
          </li>
          <li>
            <b>Самарканд (SKD):</b> сезонные рейсы в Москву и другие направления.
          </li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Azur Air — надёжная российская авиакомпания, предоставляющая доступные aviabilet narxlari и комфортные условия для пассажиров. Если вы планируете путешествие из Узбекистана в Россию или другие страны СНГ, сравните предложения Azur Air на <a href="https://biletbor.me">biletbor.me</a> и выберите наиболее подходящий рейс.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Россия</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Москва</p> 
        <h4>Основной хаб:</h4>   
        <p>Аэропорт Домодедово (DME)
           Azur Air выполняет рейсы из Москвы в различные туристические направления по всему миру, а также осуществляет перелёты из других городов России. Это позволяет пассажирам выбирать aviabilet moskva, aviabilet sankt peterburg и другие маршруты с удобными пересадками.</p>
        <h4>Вебсайт:</h4>
        <a href="https://azur-airlines.com/" target="_blank" rel="noreferrer">www.azur-airlines.com</a>
      `,
        },
      ],
    },
    belavia: {
      name: "belavia",
      pageKey: "belaviaInfo",
      imageUrl: "/images/AK/B2.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Белавиа</h1>
        <h2>Дата основания и история</h2>
        <p>Belavia — национальная авиакомпания Республики Беларусь, основанная в 1996 году на основе советского авиаперевозчика Aeroflot. С момента своего основания Belavia активно развивается, расширяя маршрутную сеть и внедряя современные технологии для улучшения качества обслуживания пассажиров. Компания стремится предоставлять aviabilet дешевые и удобные условия для путешествий как внутри Беларуси, так и за её пределами.</p>
        <h3>Флот Azal</h3>
        <p>Флот Belavia включает современные самолёты Airbus (A320, A330) и Boeing (737, 767). Эти воздушные суда обеспечивают высокий уровень комфорта и безопасности на коротко- и дальнемагистральных маршрутах. Разнообразие флота позволяет Belavia предлагать различные aviabilet narxlari в зависимости от класса обслуживания (эконом, бизнес).</p>
        <table>
          <thead>
            <tr>
              <th>Самолёт</th>
              <th>Особенности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Airbus A320</td>
              <td>Комфортные условия для эконом и бизнес-класса</td>
            </tr>
            <tr>
              <td>Boeing 737</td>
              <td>Надёжность и безопасность для коротко- и среднемагистральных маршрутов</td>
            </tr>
          </tbody>
        </table>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Belavia предлагает рейсы из следующих городов Узбекистана:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> рейсы в Минск с пересадкой или прямые рейсы (частота зависит от сезона).
          </li>
          <li>
            <b>Самарканд (SKD):</b> сезонные рейсы в Минск и другие направления.
          </li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Belavia — надёжная авиакомпания, предоставляющая доступные aviabilet narxlari и комфортные условия для пассажиров. Если вы планируете путешествие из Узбекистана в Беларусь или другие страны СНГ, сравните предложения Belavia на <a href="https://biletbor.me">biletbor.me</a> и выберите наиболее подходящий рейс.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Беларусь</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Минск</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Минск (MSQ)
           Belavia выполняет рейсы из Минска в множество городов Европы, Азии и Ближнего Востока, а также осуществляет чартерные рейсы в туристические направления. Это позволяет пассажирам выбирать aviabilet moskva, aviabilet sankt peterburg и другие маршруты с удобными пересадками.</p>
        <h4>Вебсайт:</h4>
        <a href="https://belavia.by/" target="_blank" rel="noreferrer">www.belavia.by</a>
      `,
        },
      ],
    },
    uzairways: {
      name: "uzairways",
      pageKey: "uzairwaysInfo",
      imageUrl: "/images/AK/HY.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>Uzbekistan Airways</h1>
        <h2>Дата основания и история</h2>
        <p>Uzbekistan Airways – национальная авиакомпания Узбекистана, основанная в 1992 году. За годы работы компания расширила свою маршрутную сеть, предлагая авиаперелеты в Европу, Азию и Северную Америку.</p>
        <h2>Флот Uzbekistan Airways</h2>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Boeing 787 Dreamliner</td>
                    <td>7</td>
                    <td>Дальнемагистральные маршруты</td>
                </tr>
                <tr>
                    <td>Airbus A320neo</td>
                    <td>10</td>
                    <td>Среднемагистральные маршруты</td>
                </tr>
                <tr>
                    <td>Boeing 767-300</td>
                    <td>6</td>
                    <td>Международные рейсы</td>
                </tr>
            </tbody>
        </table>
        
        <h2>Расписание рейсов из Узбекистана</h2>
        <ul>
            <li><strong>Ташкент – Москва:</strong> ежедневно</li>
            <li><strong>Ташкент – Санкт-Петербург:</strong> 3 раза в неделю</li>
            <li><strong>Самарканд – Дубай:</strong> 2 раза в неделю</li>
            <li><strong>Бухара – Стамбул:</strong> 2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Uzbekistan Airways предлагает комфортные перелеты по многим направлениям. Узнать стоимость билетов и забронировать можно на <a href="https://biletbor.me">Biletbor.me</a>.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Узбекистан</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Ташкент</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Ташкент (TAS)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.uzairways.com" target="_blank" rel="noreferrer">www.uzairways.com</a>
      `,
        },
      ],
    },
    s7: {
      name: "s7",
      pageKey: "s7Info",
      imageUrl: "/images/AK/S7.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>S7 Airlines</h1>
        <h2>Дата основания и история</h2>
        <p>S7 Airlines – крупнейшая частная авиакомпания России, основанная в 1992 году. Сегодня это один из ведущих авиаперевозчиков страны с развитой маршрутной сетью.</p>
        <h2>Флот S7 Airlines</h2>
        <p>S7 Airlines эксплуатирует современные воздушные суда, в том числе:</p>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Airbus A320neo</td>
                    <td>20</td>
                    <td>Средние и дальние маршруты</td>
                </tr>
                <tr>
                    <td>Boeing 737-800</td>
                    <td>23</td>
                    <td>Внутренние и международные рейсы</td>
                </tr>
                <tr>
                    <td>Embraer E170</td>
                    <td>17</td>
                    <td>Региональные маршруты</td>
                </tr>
            </tbody>
        </table>
        
        <h2>Расписание рейсов из Узбекистана</h2>
        <ul>
            <li><strong>Ташкент – Москва:</strong> ежедневно</li>
            <li><strong>Ташкент – Новосибирск:</strong> 2 раза в неделю</li>
            <li><strong>Самарканд – Москва:</strong> 1-2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>S7 Airlines предлагает удобные рейсы по России и международным направлениям. Узнать расписание и забронировать билеты можно на <a href="https://biletbor.me">Biletbor.me.</a></p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Россия</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Москва</p> 
        <h4>Основной хаб:</h4>   
        <p>Москва (Домодедово, DME), Новосибирск (Толмачёво, OVB)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.s7.ru" target="_blank" rel="noreferrer">www.s7.ru</a>
      `,
        },
      ],
    },
    turkish: {
      name: "turkish",
      pageKey: "turkishInfo",
      imageUrl: "/images/AK/TK.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>Turkish Airlines</h1>
        <h2>Дата основания и история</h2>
        <p>Turkish Airlines была основана в 1933 году и является национальным авиаперевозчиком Турции. Сегодня это одна из крупнейших авиакомпаний в мире.</p>
        <h2>Флот S7 Airlines</h2>
        <p>Turkish Airlines эксплуатирует один из крупнейших и самых современных парков самолетов, включая:</p>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Boeing 777-300ER</td>
                    <td>33</td>
                    <td>Дальнемагистральные рейсы</td>
                </tr>
                <tr>
                    <td>Airbus A321neo</td>
                    <td>45</td>
                    <td>Средние и короткие рейсы</td>
                </tr>
                <tr>
                    <td>Boeing 737-800</td>
                    <td>36</td>
                    <td>Региональные направления</td>
                </tr>
            </tbody>
        </table>
        
        <h2>Расписание рейсов из Узбекистана</h2>
        <p>Turkish Airlines выполняет регулярные рейсы из Ташкента и других городов Узбекистана:</p>
        <ul>
            <li><strong>Ташкент – Стамбул:</strong> ежедневно</li>
            <li><strong>Самарканд – Стамбул:</strong> 2 раза в неделю</li>
            <li><strong>Бухара – Стамбул:</strong> 1-2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Turkish Airlines предлагает удобные рейсы по всему миру. Узнать расписание и забронировать билеты можно на <a href="https://biletbor.me">Biletbor.me.</a></p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Турция</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Стамбул</p> 
        <h4>Основной хаб:</h4>   
        <p>Новый аэропорт Стамбула (IST)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.turkishairlines.com/" target="_blank" rel="noreferrer">www.turkishairlines.com</a>
      `,
        },
      ],
    },
    qanotsharq: {
      name: "qanotsharq",
      pageKey: "qanotsharqInfo",
      imageUrl: "/images/AK/HH.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>Qanot Sharq</h1>
        <h2>Дата основания и история</h2>
        <p>Авиакомпания Qanot Sharq была основана в 1998 году и специализируется на пассажирских и грузовых перевозках. За годы работы компания зарекомендовала себя как надёжный перевозчик, обеспечивающий качественный сервис на международных и внутренних направлениях.</p>
        <h2>Флот Qanot Sharq</h2>
        <p>На сегодняшний день авиакомпания эксплуатирует следующие типы воздушных судов:</p>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Airbus A320</td>
                    <td>2</td>
                    <td>Международные и внутренние рейсы</td>
                </tr>
                <tr>
                    <td>Boeing 757-200</td>
                    <td>1</td>
                    <td>Дальнемагистральные маршруты</td>
                </tr>
            </tbody>
        </table>        
        <h2>Расписание рейсов из Узбекистана</h2>
        <p>Qanot Sharq выполняет регулярные и чартерные рейсы. Основные направления:</p>
        <ul>
            <li><strong>Ташкент – Москва:</strong> ежедневно</li>
            <li><strong>Ташкент – Санкт-Петербург:</strong> 4 раза в неделю</li>
            <li><strong>Ташкент – Дубай:</strong> 3 раза в неделю</li>
            <li><strong>Ташкент – Стамбул:</strong> 2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Qanot Sharq – это удобные и доступные перелёты по популярным маршрутам. Забронируйте билеты онлайн на <a href="https://biletbor.me">biletbor.me</a> и путешествуйте с комфортом!</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Узбекистан</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Ташкент</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Ташкент (TAS)</p>
        <h4>Вебсайт:</h4>
        <a href="https://qanotsharq.com/" target="_blank" rel="noreferrer">www.qanotsharq.com</a>
      `,
        },
      ],
    },
    ural: {
      name: "ural",
      pageKey: "uralInfo",
      imageUrl: "/images/AK/U6.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>Ural Airlines</h1>
        <h2>Дата основания и история</h2>
        <p>Ural Airlines была основана в 1943 году как подразделение «Аэрофлота». В 1993 году компания стала независимой авиакомпанией и с тех пор активно расширяет свою маршрутную сеть, предлагая авиабилеты (aviabilet) на международные и внутренние рейсы.</p>
        <h2>Флот Ural Airlines</h2>
        <p>Ural Airlines располагает современным парком воздушных судов:</p>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Airbus A320</td>
                    <td>20</td>
                    <td>Среднемагистральные рейсы</td>
                </tr>
                <tr>
                    <td>Airbus A321</td>
                    <td>15</td>
                    <td>Дальнемагистральные маршруты</td>
                </tr>
                <tr>
                    <td>Airbus A319</td>
                    <td>10</td>
                    <td>Короткие и региональные рейсы</td>
                </tr>
            </tbody>
        </table>
        <h2>Расписание рейсов из Узбекистана</h2>
        <p>Ural Airlines выполняет регулярные рейсы из Ташкента и других городов:</p>
        <ul>
            <li><strong>Ташкент – Москва:</strong> 3 раза в неделю</li>
            <li><strong>Самарканд – Екатеринбург:</strong> 2 раза в неделю</li>
            <li><strong>Фергана – Москва:</strong> 1-2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Ural Airlines предлагает комфортные перелеты по России и за ее пределами. Узнать расписание и забронировать билеты можно на <a href="https://biletbor.me">Biletbor.me.</a></p>
      </article>`
        }
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Россия</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Екатеринбург</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Кольцово (SVX)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.uralairlines.ru/" target="_blank" rel="noreferrer">www.uralairlines.ru</a>
      `
        }
      ]
    },
  },
  uz: {
    aeroflot: {
      pageKey: "aeroflotInfo",
      imageUrl: "/images/AK/SU/SU.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Aeroflot</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Aeroflot (PAO «Aeroflot — Rossiya havo yo‘llari») dunyodagi eng eski aviakompaniyalardan biri bo‘lib, 1923-yilda asos solingan. Bu davr mobaynida u sobiq ittifoq davridagi monopol aviakompaniyadan tortib, hozirda butun dunyoga parvoz qiladigan yirik xalqaro tashuvchiga aylandi. Aviabilet uzbekistan yo‘nalishlarida ham ko‘p qulay reyslar mavjud.</p>
            <h3>Aeroflot flotiga qisqacha nazar</h3>
            <p>Aeroflot floti Airbus A320/321 kabi qisqa va o‘rta masofali samolyotlar, shuningdek, Boeing 777 va Airbus A330 kabi uzoq masofali samolyotlardan iborat. Zamonaviy laynerlar parvozni yanada qulay qiladi, ayniqsa aviabilet narxlari Toshkent yoki boshqa shaharlardan izlayotgan bo‘lsangiz.</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>O'ziga xos xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~100</td>
                  <td>Qisqa va o‘rta masofali yo‘nalishlar</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~20</td>
                  <td>Uzoq masofali reyslar, oshirilgan qulaylik</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~20</td>
                  <td>Qit’alararo yo‘nalishlar, keng salonlar</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Aeroflot quyidagi shaharlar orqali Moskva (Sheremetyevo) yo‘nalishida parvozlar amalga oshiradi:</p>
            <ul>
              <li>
                <b>Toshkent (TAS): </b>odatda har kuni (haftasiga 7 kun).
              </li>
              <li>
                <b>Samarqand (SKD): </b>haftasiga 2–3 marta (masalan, seshanba, payshanba, shanba).
              </li>
              <li>
                <b>Buxoro (BHK): </b>haftada 1–2 marta (ko‘pincha chorshanba va yakshanba).
              </li>
            </ul>
            <p>Aniq sana va vaqtlarni <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a> yoki rasmiy saytdan toping (Moskva aviabilet narxlari va boshqalar).</p>
            <h3>Xulosa</h3>
            <p>Aeroflot uzoq tarix, keng ko‘lamli yo‘nalishlar va zamonaviy samolyotlar bilan mashhur. Agar sizga aviabilet дешевые yoki moskva aviabilet narxlari kerak bo‘lsa, biletbor.me yoki aviakompaniyaning rasmiy sayti orqali izlashni tavsiya etamiz.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Rossiya</p> 
            <h4>Bosh ofis: </h4> 
            <p>Moskva</p> 
            <h4>Asosiy xablar: </h4>   
            <p>Sheremetyevo aeroporti (B/C terminallari), xalqaro va mintaqaviy reyslarning ko‘pchiligi shu yerdan amalga oshiriladi.</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.aeroflot.ru" target="_blank" rel="noreferrer">www.aeroflot.ru</a>
          `,
        },
      ],
    },
    airastana: {
      name: "airastana",
      pageKey: "airastanaInfo",
      imageUrl: "/images/AK/KC/KC.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Astana</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Air Astana — Qozog‘istonning milliy aviakompaniyasi bo‘lib, 2001-yilda asos solingan. Kompaniya ishga tushirilgan paytdan boshlab xizmatlar sifatini yaxshilab, aviabilet дешевые va premium tariflarini taqdim etib keladi.</p>
            <h3>Flot</h3>
            <p>Air Astana Airbus (A320, A321, A321LR/neo), Boeing (757, 767) va Embraer (E190-E2) samolyotlaridan foydalanadi. Bu turdagi zamonaviy laynerlar yo‘lovchilarga qulay parvoz imkonini beradi, xususan aviabilet narxlari borasida tanlov kengligi yuqori.</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Xizmat xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320 / A321 / A321LR</td>
                  <td>Qisqa va o‘rta masofalarga mo‘ljallangan zamonaviy laynerlar</td>
                </tr>
                <tr>
                  <td>Boeing 757 / 767</td>
                  <td>Uzoq masofali, yuqori ishonchlilikka ega samolyotlar</td>
                </tr>
                <tr>
                  <td>Embraer E190-E2</td>
                  <td>Qisqa masofaga uchish uchun qulay regiоnal samolyotlar</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Air Astana O‘zbekistondan to‘g‘ridan-to‘g‘ri yoki ulanishli reyslar taklif etadi:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Olmaota yoki Astana (haftada bir necha marta)
              </li>
              <li>
                <b>Samarqand (SKD):</b> mavsumiy reyslar (aniq ma’lumotni biletbor.me saytidan bilib oling)
              </li>
            </ul>
            <p>Ushbu reyslardan keyin Moskvaga (aviabilet moskva), Sankt-Peterburgga (aviabilet sankt peterburg) yoki boshqa yo‘nalishlarga uchishni <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a> saytida davom ettirish mumkin.</p>
            <h3>Xulosa</h3>
            <p>Air Astana — Qozog‘istonning zamonaviy va jadal rivojlanayotgan aviakompaniyasi bo‘lib, O‘zbekiston hamda dunyoning boshqa ko‘plab shaharlariga qulay reyslar taqdim etadi. Agar siz qulaylik va maqbul aviabilet narxlarini qidirsangiz, Air Astana reyslariga e’tibor berishingiz mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Казахстан</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Алматы</p> 
            <h4>Основной хаб: </h4>   
            <p>Международные аэропорты Алматы и Астаны
              Air Astana осуществляет рейсы в страны СНГ, Азии, Ближнего Востока и Европы, давая пассажирам возможность сравнивать aviabilet moskva и aviabilet sankt peterburg с другими направлениями
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.airastana.com" target="_blank" rel="noreferrer">www.airastana.com</a>
          `,
        },
      ],
    },
    airchina: {
      name: "airchina",
      pageKey: "airchinaInfo",
      imageUrl: "/images/AK/CA.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air China</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Air China — Xitoyning milliy aviakompaniyasi bo‘lib, 1988-yildan boshlab bir nechta mintaqaviy kompaniyalarni birlashtirish natijasida shakllangan. Hozirda Air China Osiyodagi eng yirik aviakompaniyalardan biri sifatida aviabilet дешевые dan tortib premium xizmatlargacha keng turdagi imkoniyatlar taqdim etadi.</p>
            <h3>Air China floti</h3>
            <p>Air China turli samolyot turlaridan foydalanadi: Boeing 737, 777, 787 hamda Airbus A320, A330, A350. Ushbu park uzoq masofali va mahalliy reyslarni yuqori darajadagi xavfsizlik va xizmat sifati bilan amalga oshirishga imkon beradi. Aviabilet narxlari har xil bo‘lib, ekonomdan tortib biznes yoki birinchi klassgacha tanlov mavjud.</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Xizmat xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737, 777, 787</td>
                  <td>O‘rta va uzoq masofali reyslar, turli qulaylik darajalari</td>
                </tr>
                <tr>
                  <td>Airbus A320, A330, A350</td>
                  <td>Ichki va xalqaro yo‘nalishlar uchun keng qamrovli zamonaviy laynerlar</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Air China Uzbеkistondan to‘g‘ridan-to‘g‘ri yoki ulanishli reyslarni taklif etishi mumkin:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> ko‘pincha Pekin yoki boshqa xab orqali (to‘liq ma’lumotni <a href="https://biletbor.me">biletbor.me</a> da toping).</a>.
              </li>
              <li>
                <b>Boshqa shaharlar: </b>mavsumiy yoki charter reyslar bo‘lishi ehtimoli bor.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Air China — Osiyodagi yirik aviakompaniya bo‘lib, O‘zbekiston va Xitoy o‘rtasida qulay yo‘nalishlar taqdim etadi. Agar siz uzoq masofaga qulay parvoz yoki aviabilet narxlari bo‘yicha raqobatbardosh taklif qidirsangiz, Air China reyslari sizga mos kelishi mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Xitoy</p> 
            <h4>Bosh ofis: </h4> 
            <p>Pekin</p> 
            <h4>Asosiy xab: </h4>   
            <p>Pekin poytaxt xalqaro aeroporti (PEK)
               Shuningdek, Chendu, Shanxay va boshqa shaharlardagi aeroportlar qo‘shimcha xab sifatida foydalaniladi. Yo‘lovchilar aviabilet moskva yoki aviabilet sankt peterburg kabi yo‘nalishlarni ham qulay ulanishlar bilan tanlashlari mumkin.
            </p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.aircairo.com" target="_blank" rel="noreferrer">www.aircairo.com</a>
          `,
        },
      ],
    },
    airmanas: {
      name: "airmanas",
      pageKey: "airmanasInfo",
      imageUrl: "/images/AK/ZM.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Manas</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Air Manas — Qirg‘iziston aviakompaniyasi bo‘lib, 2006-yilda faoliyatini boshlagan. Dastlab ichki va qo‘shni davlatlarga parvoz qilgan kompaniya, hozirda yo‘lovchilarga aviabilet дешевые va qulay shart-sharoitlar taklif qiladi.</p>
            <h3>Air Manas floti</h3>
            <p>Air Manas odatda Boeing 737 turidagi samolyotlardan foydalanadi. Ushbu laynerlar qisqa va o‘rta masofaga mo‘ljallangan bo‘lib, aviabilet narxlari bo‘yicha hamyonbop parvozlarni amalga oshirishga imkon beradi.</p>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Air Manas O‘zbekistondan:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Bishkek yo‘nalishida reyslar (mavsumga va talabga qarab o‘zgarishi mumkin)..
              </li>
              <li>
                <b>Boshqa shaharlar: </b>ba’zan mavsumiy yoki charter reyslar ham bo‘lishi mumkin.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Air Manas — Qirg‘iziston va O‘zbekiston o‘rtasida parvozni rejalashtirayotgan yoki iqtisodiy jihatdan aviabilet narxlari qulay bo‘lgan reys qidirayotganlar uchun yaxshi tanlov. biletbor.me orqali tariflarni solishtirish mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Qirg‘iziston</p> 
            <h4>Bosh ofis: </h4> 
            <p>Bishkek</p> 
            <h4>Asosiy xab: </h4>   
            <p>Manas xalqaro aeroporti (FRU)
               Air Manas MDH davlatlari va mintaqadagi boshqa davlatlarga parvozlar amalga oshiradi. Bu aviabilet moskva yoki aviabilet sankt peterburg kabi yo‘nalishlarda ham qulay ulanish imkonini beradi.
            </p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.airmanas.com" target="_blank" rel="noreferrer">www.airmanas.com</a>
          `,
        },
      ],
    },
    asianaAirlines: {
      name: "asianaAirlines",
      pageKey: "asianaAirlinesInfo",
      imageUrl: "/images/AK/OZ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Asiana Airlines</h1>
            <h2>Asiana Airlines tashkil topgan yili va qisqacha tarix</h2>
            <p>Asiana Airlines — 1988-yilda asos solingan Koreya Respublikasi (Janubiy Koreya)ning yirik aviakompaniyalaridan biri. Kompaniya yuqori darajadagi xizmat va xavfsizlikni ta’minlagani bois e’tibor qozongan, shu jumladan, aviabilet дешевые va turli toifadagi tariflarni O‘zbekiston yo‘nalishiga ham taklif etadi.</p>
            <h3>Flot</h3>
            <p>Asiana Airlines Airbus (A320, A350) va Boeing (B747, B777) samolyotlaridan foydalanadi. Bu turli yo‘nalishlar — qisqa masofadan tortib uzoq masofagacha — uchun qulay. Aviabilet narxlariga ko‘ra, ekonom, biznes yoki birinchi klassni tanlash imkoniyati mavjud.</p>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Asiana Airlines O‘zbekistondan to‘g‘ridan-to‘g‘ri yoki ulanishli reyslarni taklif qilishi mumkin:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> ko‘pincha Seul (Incheon) orqali (mavsumga qarab).
              </li>
              <li>
                <b>Boshqa shaharlar: </b>mavsumiy charter reyslar bo‘lishi ehtimoli bor.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Asiana Airlines Koreya bozoridagi eng mashhur aviakompaniyalardan biri bo‘lib, O‘zbekiston yo‘lovchilari uchun ham qulay parvozlar taklif qiladi. Agar siz uzoq masofali yoki aviabilet narxlari bo‘yicha tanlovni qidirsangiz, Asiana Airlines’ning rasmiy sayti yoki <a href="https://biletbor.me">biletbor.me</a> sizga yordam beradi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Koreya Respublikasi</p> 
            <h4>Bosh ofis: </h4> 
            <p>Seul</p> 
            <h4>Asosiy xab: </h4>   
            <p>Incheon xalqaro aeroporti (ICN)
               Asiana Airlines Seul Gimpo aeroporti va boshqa shaharlardan ham reyslar amalga oshiradi. Bu orqali aviabilet moskva yoki aviabilet sankt peterburg yo‘nalishlarida qulay ulanishlar topish mumkin.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://www.flyasiana.com" target="_blank" rel="noreferrer">www.flyasiana.com</a>
          `,
        },
      ],
    },
    aviaTraffic: {
      name: "aviaTraffic",
      pageKey: "aviaTrafficInfo",
      imageUrl: "/images/AK/AT.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Avia Traffic Company</h1>
            <h2>Avia Traffic Company tashkil topgan yili va qisqacha tarix</h2>
            <p>Avia Traffic Company — Qirg‘iziston aviakompaniyasi bo‘lib, 2003-yildan buyon faoliyat yuritadi. U bosqichma-bosqich o‘z yo‘nalishlarini kengaytirib, aviabilet дешевые va turli qulay xizmatlarni taqdim etishga intiladi.</p>
            <h3>Flot</h3>
            <p>Avia Traffic Company floti odatda Boeing 737 va Airbus A320 samolyotlaridan iborat:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot</th>
                  <th>Xizmat xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>Qisqa va o‘rta masofalardagi yo‘nalishlar uchun</td>
                </tr>
                <tr>
                  <td>Airbus A320</td>
                  <td>Ekonom va biznes-klass, qulay salon</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Avia Traffic Company O‘zbekistondan:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Bishkek (va qaytish) yo‘nalishida reyslar, mavsumga qarab turli chastota.
              </li>
              <li>
                <b>Boshqa shaharlar: </b>Ba’zan charter yoki mavsumiy reyslar mavjud bo‘lishi mumkin.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Avia Traffic Company — Qirg‘iziston va O‘zbekiston o‘rtasida parvoz qiluvchi qulay aviakompaniya. Agar siz tejamkor aviabilet narxlari va hamyonbop tariflarni qidirsangiz, <a href="https://biletbor.me">biletbor.me</a> orqali ushbu kompaniya reyslarini ko‘rib chiqishingiz mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Qirg‘iziston</p> 
            <h4>Bosh ofis: </h4> 
            <p>Bishkek</p> 
            <h4>Asosiy xab: </h4>   
            <p>Manas xalqaro aeroporti (FRU)
               Kompaniya MDH davlatlariga muntazam va charter reyslar amalga oshiradi. Kelajakda yo‘nalishlar sonini ko‘paytirish rejalari bor, bu esa aviabilet moskva yoki aviabilet sankt peterburg yo‘nalishlarini ham qulay ulanish bilan ta’minlashi mumkin.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://www.aero.kg/" target="_blank" rel="noreferrer">www.aviatraffic.com</a>
          `,
        },
      ],
    },
    azal: {
      name: "azal",
      pageKey: "azalInfo",
      imageUrl: "/images/AK/J2.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Azal Havo Yo'llari</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>AZAL (Azerbaijan Airlines) — Ozarbayjonning milliy aviakompaniyasi bo‘lib, 1992-yilda davlat aviatsiyasi bazasida tashkil etilgan. Shundan beri kompaniya aviabilet дешевые va qulay tariflarni taklif qilib, bir qator xalqaro yo‘nalishlarda faoliyat yuritadi.</p>
            <h3>Flot</h3>
            <p>AZAL floti asosan Airbus (A319, A320, A321) va Boeing (757, 767, 787) samolyotlaridan iborat. Bu turli masofalarga mo‘ljallangan reyslarni amalga oshirishga imkon beradi va aviabilet narxlarini turlicha shakllantiradi.</p>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>AZAL O‘zbekistondan:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Boku yo‘nalishi (haftadagi qatnovlar mavsum va talabga qarab o‘zgarishi mumkin).
              </li>
              <li>
                <b>Boshqa shaharlar:</b> ba’zi mavsumiy yoki charter reyslar bo‘lishi mumkin.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>AZAL (Azerbaijan Airlines) O‘zbekiston va Ozarbayjonni bog‘lovchi qulay aviakompaniya. Boku orqali uzoq masofalarga uchish uchun ham qulay ulanishlarni taqdim etadi. Eng yaxshi aviabilet narxlarini <a href="https://biletbor.me">biletbor.me</a> sayti orqali qidirishingiz mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Ozarbayjon</p> 
            <h4>Bosh ofis: </h4> 
            <p>Boku</p> 
            <h4>Asosiy xab: </h4>   
            <p>Geydar Aliyev xalqaro aeroporti (GYD)
               AZAL Yevropa, Osiyo va MDH davlatlariga reyslar amalga oshiradi. Bu aviabilet moskva yoki aviabilet sankt peterburg kabi yo‘nalishlar bo‘yicha ham qulay ulanishni ta’minlaydi, xususan O‘zbekistondan uchmoqchi bo‘lganlar uchun.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://www.azal.az/" target="_blank" rel="noreferrer">www.azal.az</a>
          `,
        },
      ],
    },
    azimuth: {
      name: "azimuth",
      pageKey: "azimuthInfo",
      imageUrl: "/images/AK/A4.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Azimut</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Azimut — 1993-yilda tashkil etilgan Rossiya aviakompaniyasi. Tashkil topganidan buyon kompaniya mintaqaviy parvozlar bilan shug‘ullanadi, yo‘lovchilarga aviabilet дешевые va qulay sharoitlarni taqdim etadi. Azimuth Rossiya va qo‘shni davlatlarga yo‘nalishlar bo‘yicha keng xizmat ko‘rsatadi.</p>
            <h3>Flot</h3>
            <p>Azimut floti zamonaviy Embraer ERJ-145 va ATR 72 samolyotlaridan iborat. Ushbu samolyotlar qisqa va o‘rta masofadagi yo‘nalishlar uchun juda mos keladi, yo‘lovchilarga qulay va xavfsiz parvozlarni ta’minlaydi. Aviabilet narxlarini tanlashda ekonom va biznes klasslarini topish mumkin.</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot</th>
                  <th>Xizmat xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Embraer ERJ-145</td>
                  <td>Qisqa va o‘rta masofadagi yo‘nalishlar uchun ideal</td>
                </tr>
                <tr>
                  <td>ATR 72</td>
                  <td>Qisqa parvozlar uchun iqtisodiy samolyot</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Azimut O‘zbekistondan quyidagi shaharlar orqali reyslarni taklif etadi:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Domodedovo (Moskva) yo‘nalishi bilan parvozlar (mavsumga qarab chastota o‘zgarishi mumkin).
              </li>
              <li>
                <b>Samarqand (SKD):</b> Moskva va boshqa yo‘nalishlarga mavsumiy reyslar.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Azimut — Rossiya aviakompaniyasi bo‘lib, yo‘lovchilarga arzon aviabilet narxlari va qulay sharoitlarni taqdim etadi. Agar siz O‘zbekistonlik bo‘lsangiz va Rossiyaga yoki MDH davlatlariga sayohat qilmoqchi bo‘lsangiz, Azimutning takliflarini <a href="https://biletbor.me">biletbor.me</a> orqali solishtiring va eng mos reysni tanlang.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Ozarbayjon</p> 
            <h4>Bosh ofis: </h4> 
            <p>Boku</p> 
            <h4>Asosiy xab: </h4>   
            <p>Geydar Aliyev xalqaro aeroporti (GYD)
               AZAL Yevropa, Osiyo va MDH davlatlariga reyslar amalga oshiradi. Bu aviabilet moskva yoki aviabilet sankt peterburg kabi yo‘nalishlar bo‘yicha ham qulay ulanishni ta’minlaydi, xususan O‘zbekistondan uchmoqchi bo‘lganlar uchun.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://www.azal.az/" target="_blank" rel="noreferrer">www.azal.az</a>
          `,
        },
      ],
    },
    azurair: {
      name: "azurair",
      pageKey: "azurairInfo",
      imageUrl: "/images/AK/ZF.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Azur Air</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Azur Air — 2006-yilda tashkil etilgan Rossiya aviakompaniyasi. Tashkil topganidan buyon kompaniya turist yo‘nalishlaridagi charter reyslarga ixtisoslashgan. So‘nggi yillarda Azur Air o‘z yo‘nalishlar tarmog‘ini kengaytirdi, muntazam reyslar va yangi yo‘nalishlarni taqdim etib, yo‘lovchilarga aviabilet дешевые va qulay parvoz sharoitlarini taqdim etish imkoniyatini yaratdi.</p>
            <h3>Flot</h3>
            <p>Azur Air floti asosan zamonaviy Airbus A320 va Boeing 737 samolyotlaridan iborat. Ushbu samolyotlar qisqa va o‘rta masofadagi yo‘nalishlar uchun komfort va xavfsizlikni ta’minlaydi. Flotning xilma-xilligi Azur Air’ga turli toifadagi aviabilet narxlarini taklif etishga imkon beradi (ekonom, biznes).</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>Ekonom va biznes-klass uchun qulay sharoitlar</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>Qisqa va o‘rta masofali yo‘nalishlar uchun ishonchlilik va xavfsizlik</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Azur Air O‘zbekistondan quyidagi shaharlar orqali reyslarni taklif etadi:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Domodedovo (Moskva) yo‘nalishi bilan parvozlar (mavsumga bog‘liq chastota o‘zgarishi mumkin).
              </li>
              <li>
                <b>Samarqand (SKD):</b> Moskva va boshqa yo‘nalishlarga mavsumiy reyslar.
              </li>
            </ul>
            <p>Akkurat jadval va aviabilet narxlarini <a href="https://biletbor.me">biletbor.me</a> yoki aviakompaniya rasmiy saytidan tekshirish tavsiya etiladi.</p>
            <h3>Xulosa</h3>
            <p>Azur Air — ishonchli Rossiya aviakompaniyasi bo‘lib, yo‘lovchilarga arzon aviabilet narxlari va qulay sharoitlarni taqdim etadi. Agar siz O‘zbekistonlik bo‘lsangiz va Rossiyaga yoki MDH davlatlariga sayohat qilmoqchi bo‘lsangiz, Azur Air’ning takliflarini <a href="https://biletbor.me">biletbor.me</a> orqali solishtiring va eng mos reysni tanlang.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Rossiya</p> 
            <h4>Bosh ofis: </h4> 
            <p>Moskva</p> 
            <h4>Asosiy xab: </h4>   
            <p>Domodedovo xalqaro aeroporti (DME)
               Azur Air Moskva va Rossiyaning boshqa yirik shaharlardan turli turist yo‘nalishlariga reyslar amalga oshiradi. Bu yo‘lovchilarga aviabilet moskva, aviabilet sankt peterburg va boshqa yo‘nalishlarda qulay ulanishlar bilan tanlash imkonini beradi.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://azur-airlines.com/" target="_blank" rel="noreferrer">www.azur-airlines.com</a>
          `,
        },
      ],
    },
    belavia: {
      name: "belavia",
      pageKey: "belaviaInfo",
      imageUrl: "/images/AK/B2.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Belavia</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Belavia — Belarus Respublikasining milliy aviakompaniyasi bo‘lib, 1996-yilda sobiq Sovet aviakompaniyasi Aeroflot asosida tashkil etilgan. Tashkil topganidan buyon Belavia faol ravishda rivojlanib, yo‘nalishlar tarmog‘ini kengaytirib, yo‘lovchilarga yuqori sifatli xizmat ko‘rsatishni maqsad qilgan. Kompaniya yo‘lovchilarga aviabilet дешевые va qulay parvoz sharoitlarini taqdim etishga intiladi.</p>
            <h3>Flot</h3>
            <p>Belavia floti zamonaviy Airbus (A320, A330) va Boeing (737, 767) samolyotlaridan iborat. Ushbu samolyotlar qisqa va uzoq masofalardagi yo‘nalishlar uchun yuqori darajadagi komfort va xavfsizlikni ta’minlaydi. Flotning xilma-xilligi Belavia’ga turli toifadagi aviabilet narxlarini taklif etishga imkon beradi (ekonom, biznes).</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>Ekonom va biznes-klass uchun qulay sharoitlar</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>Qisqa va o‘rta masofali yo‘nalishlar uchun ishonchlilik va xavfsizlik</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Belavia O‘zbekistondan quyidagi shaharlar orqali reyslarni amalga oshirishi mumkin:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Minsk yo‘nalishi bilan parvozlar (mavsumga qarab chastota o‘zgarishi mumkin).
              </li>
              <li>
                <b>Samarqand (SKD):</b> Minsk va boshqa yo‘nalishlarga mavsumiy reyslar.
              </li>
            </ul>
            <p>Aniq jadval va aviabilet narxlarini <a href="https://biletbor.me">biletbor.me</a> yoki aviakompaniya rasmiy saytidan tekshirish tavsiya etiladi.</p>
            <h3>Xulosa</h3>
            <p>Belavia — ishonchli aviakompaniya bo‘lib, yo‘lovchilarga arzon aviabilet narxlari va qulay sharoitlarni taqdim etadi. Agar siz O‘zbekistonlik bo‘lsangiz va Belarusga yoki boshqa MDH davlatlariga sayohat qilmoqchi bo‘lsangiz, Belavia’ning takliflarini <a href="https://biletbor.me">biletbor.me</a> orqali solishtiring va eng mos reysni tanlang.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Belarus</p> 
            <h4>Bosh ofis: </h4> 
            <p>Minsk</p> 
            <h4>Asosiy xab: </h4>   
            <p>Minsk xalqaro aeroporti (MSQ)
               Belavia Minskdan Yevropa, Osiyo va Yaqin Sharqning ko‘plab shaharlarga reyslar amalga oshiradi, shuningdek, turistik yo‘nalishlarga charter reyslarni ham taqdim etadi. Bu yo‘lovchilarga aviabilet moskva, aviabilet sankt peterburg kabi yo‘nalishlarni qulay ulanishlar bilan tanlash imkonini beradi.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://belavia.by/" target="_blank" rel="noreferrer">www.belavia.by</a>
          `,
        },
      ],
    },
    uzairways: {
      name: "uzairways",
      pageKey: "uzairwaysInfo",
      imageUrl: "/images/AK/HY.svg",
      generalInfo: [
        {
          content: `
            <article>
              <h1>O'zbekiston Havo Yo'llari</h1>
              <h2>Tashkil topgan yili va tarixi</h2>
              <p>Uzbekistan Airways 1992 yilda tashkil etilgan bo‘lib, O‘zbekistonning milliy aviakompaniyasidir. Kompaniya keng yo‘nalish tarmog‘i va yuqori xizmat ko‘rsatish darajasi bilan mashhur.</p>
              <h2>Uzbekistan Airways samolyot parki</h2>
        <table>
            <thead>
                <tr>
                    <th>Samolyot turi</th>
                    <th>Soni</th>
                    <th>Yo‘nalishlar</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Boeing 787 Dreamliner</td>
                    <td>7</td>
                    <td>Uzoq masofali reyslar</td>
                </tr>
                <tr>
                    <td>Airbus A320neo</td>
                    <td>10</td>
                    <td>O‘rta masofali yo‘nalishlar</td>
                </tr>
                <tr>
                    <td>Boeing 767-300</td>
                    <td>6</td>
                    <td>Xalqaro reyslar</td>
                </tr>
            </tbody>
        </table>
        
        <h2>O‘zbekistondan reyslar jadvali</h2>
        <ul>
            <li><strong>Toshkent – Moskva:</strong> har kuni</li>
            <li><strong>Toshkent – Sankt-Peterburg:</strong> haftasiga 3 marta</li>
            <li><strong>Samarqand – Dubay:</strong> haftasiga 2 marta</li>
            <li><strong>Buxoro – Istanbul:</strong> haftasiga 2 marta</li>
        </ul>
              <h2>Xulosa</h2>
        <p>Uzbekistan Airways yo‘lovchilarga keng yo‘nalishlar tarmog‘ini va yuqori darajadagi xizmatni taklif etadi. Chipta narxlari va bron qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring.</p>
            </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Davlat:</h4>
        <p>O‘zbekiston</p> 
        <h4>Bosh ofis:</h4> 
        <p>Toshkent</p> 
        <h4>Asosiy xab:</h4>   
        <p>Toshkent xalqaro aeroporti (TAS)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.uzairways.com" target="_blank" rel="noreferrer">www.uzairways.com</a>
      `,
        },
      ],
    },
    s7: {
      imageUrl: "/images/AK/S7.svg",
      generalInfo: [
        {
          content: `
            <article>
              <h1>S7 Airlines</h1>
              <h2>Tashkil topgan yili va tarixi</h2>
              <p>S7 Airlines 1992 yilda tashkil etilgan bo‘lib, Rossiyaning eng yirik xususiy aviakompaniyalaridan biridir. Kompaniya muntazam ravishda xizmat tarmog‘ini kengaytirib bormoqda.</p>
              <h2>S7 Airlines samolyot parki</h2>
              <p>S7 Airlines zamonaviy samolyotlarga ega:</p>
        <table>
            <thead>
                <tr>
                    <th>Samolyot turi</th>
                    <th>Soni</th>
                    <th>Yo‘nalishlar</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Airbus A320neo</td>
                    <td>20</td>
                    <td>O‘rta va uzoq masofali reyslar</td>
                </tr>
                <tr>
                    <td>Boeing 737-800</td>
                    <td>23</td>
                    <td>Ichki va xalqaro yo‘nalishlar</td>
                </tr>
                <tr>
                    <td>Embraer E170</td>
                    <td>17</td>
                    <td>Mintaqaviy reyslar</td>
                </tr>
            </tbody>
        </table>
        
        <h2>O‘zbekistondan reyslar jadvali</h2>
        <ul>
            <li><strong>Toshkent – Moskva:</strong> har kuni</li>
            <li><strong>Toshkent – Novosibirsk:</strong> haftasiga 2 marta</li>
            <li><strong>Samarqand – Moskva:</strong> 1-2 marta haftasiga</li>
        </ul>
              <h2>Xulosa</h2>
        <p>S7 Airlines – O‘zbekiston va Rossiya o‘rtasidagi ishonchli havo yo‘lovchi tashuvchisi. Aviabilet narxlari va bron qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring.</p>
            </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Davlat:</h4>
        <p>Rossiya</p> 
        <h4>Bosh ofis:</h4> 
        <p>Moskva</p> 
        <h4>Asosiy xab:</h4>   
        <p>Moskva (Domodedovo, DME), Novosibirsk (Tolmachëvo, OVB)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.s7.ru/" target="_blank" rel="noreferrer">www.s7.ru</a>
      `,
        },
      ],
    },
    qanotsharq: {
      name: "qanotsharq",
      pageKey: "qanotsharqInfo",
      imageUrl: "/images/AK/HH.svg",
      generalInfo: [
        {
          content: `
            <article>
              <h1>Qanot Sharq</h1>
              <h2>Tashkil topgan yili va tarixi</h2>
              <p>Qanot Sharq aviakompaniyasi 1998 yilda tashkil etilgan bo‘lib, yo‘lovchi va yuk tashish xizmatlarini ko‘rsatadi. Yillar davomida kompaniya ishonchli tashuvchi sifatida o‘z mavqeini mustahkamladi.</p>
              <h2>Qanot Sharq samolyot parki</h2>
              <p>Bugungi kunda kompaniya quyidagi samolyotlarni ekspluatatsiya qiladi:</p>
              <table>
                  <thead>
                      <tr>
                          <th>Samolyot turi</th>
                          <th>Soni</th>
                          <th>Asosiy yo‘nalishlar</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>Airbus A320</td>
                          <td>2</td>
                          <td>Xalqaro va mahalliy reyslar</td>
                      </tr>
                      <tr>
                          <td>Boeing 757-200</td>
                          <td>1</td>
                          <td>Uzoq masofali yo‘nalishlar</td>
                      </tr>
                  </tbody>
              </table>
        <h2>O‘zbekistondan reyslar jadvali</h2>
        <ul>
            <li><strong>Toshkent – Moskva:</strong> har kuni</li>
            <li><strong>Toshkent – Sankt-Peterburg:</strong> haftada 4 marta</li>
            <li><strong>Toshkent – Dubay:</strong> haftada 3 marta</li>
            <li><strong>Toshkent – Istanbul:</strong> haftada 2 marta</li>
        </ul>
        <h2>Xulosa</h2>
        <p>Qanot Sharq – mashhur yo‘nalishlar bo‘ylab qulay va arzon parvozlarni taklif etadi. Aviabiletlarni onlayn band qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring!</p>
            </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Davlat:</h4>
        <p>O‘zbekiston</p> 
        <h4>Bosh ofis:</h4> 
        <p>Toshkent</p> 
        <h4>Asosiy xab:</h4>   
        <p>Toshkent xalqaro aeroporti (TAS)</p>
        <h4>Вебсайт:</h4>
        <a href="https://qanotsharq.com/" target="_blank" rel="noreferrer">www.qanotsharq.com</a>
      `,
        },
      ],
    },
    ural: {
      pageKey: "uralInfo",
      imageUrl: "/images/AK/U6.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Ural Havo Yo'llari</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Ural Airlines 1943 yilda tashkil etilgan bo‘lib, dastlab «Aeroflot» tarkibida bo‘lgan. 1993 yildan boshlab mustaqil aviakompaniya sifatida faoliyat yuritmoqda. Kompaniya Rossiya, MDH, Yevropa va Osiyoga muntazam parvozlarni amalga oshiradi.</p>
            <h3>Ural Airlines samolyot parki</h3>
            <p>Ural Airlines zamonaviy samolyotlarga ega:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>O'ziga xos xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~20</td>
                  <td>o‘rta va uzoq masofali yo‘nalishlar uchun</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~15</td>
                  <td>keng sig‘imli parvozlar uchun</td>
                </tr>
                <tr>
                  <td>Airbus A319</td>
                  <td>~10</td>
                  <td>qisqa masofali reyslar uchun</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Ural Airlines O‘zbekistonning yirik shaharlari bilan bog‘lanishni ta’minlaydi:</p>
            <ul>
              <li>
                <b>Toshkent – Moskva: </b>haftasiga 3 marta.
              </li>
              <li>
                <b>Samarqand – Yekaterinburg: </b>haftasiga 2 marta.
              </li>
              <li>
                <b>Farg‘ona – Moskva: </b>1-2 marta haftasiga.
              </li>
            </ul>
            <p>Aniq sana va vaqtlarni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki rasmiy saytdan toping (Moskva aviabilet narxlari va boshqalar).</p>
            <h3>Xulosa</h3>
            <p>Ural Airlines – O‘zbekiston va Rossiya o‘rtasidagi ishonchli havo yo‘lovchi tashuvchisi. Aviabilet narxlari va bron qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Rossiya</p> 
            <h4>Bosh ofis: </h4> 
            <p>Yekaterinburg</p> 
            <h4>Asosiy xablar: </h4>   
            <p>Keltso xalqaro aeroporti (SVX)</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.uralairlines.ru/" target="_blank" rel="noreferrer">www.uralairlines.ru</a>
          `,
        },
      ],
    },
  },
  en: {
    aeroflot: {
      name: "Aeroflot",
      pageKey: "aeroflotInfo",
      imageUrl: "/images/AK/SU.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Aeroflot</h1>
            <h2>Foundation and History</h2>
            <p>Aeroflot (PJSC "Aeroflot – Russian Airlines") is one of the oldest airlines in the world, founded in 1923. Over its long history, it has evolved from a Soviet-era monopoly to a major international carrier, offering flight tickets (aviabilet) for numerous routes, including services to Uzbekistan.</p>
            <h3>Aeroflot Fleet</h3>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Approx. Number</th>
                  <th>Key Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~100</td>
                  <td>Short- and medium-haul routes</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~20</td>
                  <td>Long-haul flights, enhanced comfort</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~20</td>
                  <td>Intercontinental routes, spacious cabins</td>
                </tr>
              </tbody>
            </table>
            <p>Aeroflot operates a modern fleet primarily consisting of Airbus and Boeing aircraft. This includes the short- and medium-haul Airbus A320/321 series, as well as the long-haul Boeing 777 and Airbus A330.</p>
            <h3>Flight Schedule from Uzbekistan</h3>
            <p>Aeroflot operates direct flights from these Uzbek cities to Moscow (Sheremetyevo):</p>
            <ul>
              <li>
                <b>Tashkent (TAS):</b> typically daily (7 times a week).
              </li>
              <li>
                <b>Samarkand (SKD):</b> 2–3 times a week.
              </li>
              <li>
                <b>Bukhara (BHK):</b> 1–2 times a week.
              </li>
            </ul>
            <p>Check tickets and the latest departure days on the official website or 
            <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a>.</p>
            <h3>Summary</h3>
            <p>Aeroflot combines decades of experience, an extensive route network, and a modern fleet. If you are looking for flight tickets (aviabilet) to Moscow or other destinations, make sure to compare prices and take advantage of available promotions on biletbor.me or through the official website.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Russia</p>
            <h4>Headquarters:</h4>
            <p>Moscow</p>
            <h4>Main Hub:</h4>
            <p>Sheremetyevo International Airport (Terminals B/C), serving most international and regional flights</p>
            <h4>Website:</h4>
            <a href="https://www.aeroflot.ru" target="_blank" rel="noreferrer">www.aeroflot.ru</a>
          `,
        },
      ],
    },
  },
};

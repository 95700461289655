import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SeoTags } from "../../components";
import { PartnersListComponent } from "../../components/info/PartnersComponent/PartnersInfoComponent/PartnersListComponent";
import { setSearchBlockCompact } from "../../store/appConfigsSlice";

const SuppliersPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchBlockCompact(true));
  }, []);

  return (
    <>
      <SeoTags pageKey="" />
      <PartnersListComponent />
    </>
  );
};

export default SuppliersPage;

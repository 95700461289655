import {withTranslation} from "react-i18next";
import {ScoreboardComponent} from "../../components/scoreboard/ScoreboardComponent";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setSearchBlockCompact} from "../../store/appConfigsSlice";

const ScoreboardPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSearchBlockCompact(true));
    }, []);

    return (
        <>
            <ScoreboardComponent />
        </>
    )
}

export default withTranslation()(ScoreboardPage);
const metaKeywords = {
  ru: {
    homePage: "",
    aeroflotInfo: "Аэрофлот, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet narxlari toshkent, moskva aviabilet narxlari, купить авиабилеты, авиабилеты Аэрофлот",
    airastanaInfo: "Air Astana, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    aircairoInfo: "Air Cairo, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    airchinaInfo: "Air China, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    airmanasInfo: "Air Manas, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    asianaAirlinesInfo: "Asiana Airlines, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    aviaTrafficInfo: "Avia Traffic Company, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    azalInfo: "AZAL, Azerbaijan Airlines, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    azimuthInfo: "Azimuth, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    azurairInfo: "Azur Air, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    belaviaInfo: "Belavia, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    uzairways: "aviabilet, авиабилет, Uzbekistan Airways, авиабилеты Ташкент, рейсы Узбекистан, купить билет на самолет, авиаперелеты",
    s7Info: "viabilet, авиабилет, S7 Airlines, авиабилеты Ташкент, рейсы S7, авиабилеты Москва, купить авиабилет дешево",
    turkishInfo: "aviabilet, авиабилет, Turkish Airlines, авиабилеты Ташкент, рейсы Turkish Airlines, авиабилеты Стамбул, купить авиабилет дешево",
    qanotsharqInfo: "qanot sharq, авиабилеты, авиабилеты ташкент, дешёвые авиабилеты, билеты узбекистан, билеты москва ташкент, qanot sharq расписание",
    uralInfo: "aviabilet, авиабилет, Ural Airlines, авиабилеты Ташкент, рейсы Ural Airlines, авиабилеты Москва, купить авиабилет дешево",
  },
  en: {
    homePage: "",
    aeroflotInfo:
      "Аэрофлот, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet narxlari toshkent, moskva aviabilet narxlari, купить авиабилеты, авиабилеты Аэрофлот",
  },
  uz: {
    homePage: "",
    aeroflotInfo:
      "Аэрофлот, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet narxlari toshkent, moskva aviabilet narxlari, купить авиабилеты, авиабилеты Аэрофлот",
    uzairways: "aviabilet, авиабилет, Uzbekistan Airways, авиабилеты Ташкент, рейсы Узбекистан, купить билет на самолет, авиаперелеты",
    s7Info: "aviabilet, aviabilet narxlari, S7 Airlines, aviabilet Toshkent, samolyot chiptalari, havo yo‘nalishlari",
    turkishInfo: "aviabilet, aviabilet narxlari, Turkish Airlines, aviabilet Toshkent, samolyot chiptalari, havo yo‘nalishlari",
    qanotsharqInfo: "qanot sharq, aviabilet, aviabilet narxlari, aviabilet tashkent, aviabilet moskva, aviabilet sankt peterburg, qanot sharq reyslari",
    uralInfo: "aviabilet, aviabilet narxlari, Ural Airlines, ural, aviabilet Toshkent, samolyot chiptalari, havo yo‘nalishlari",
  },
};

export default metaKeywords;

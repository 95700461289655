import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({

}));

export const LogoLink = styled(Link)(({ theme }) => ({
  "& > img": {
    width: "100%",
    maxWidth: "170px",
    height: "auto",

    [theme.down("md")]: {
      maxWidth: "100px"
    }
  },
}));

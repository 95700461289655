export const allAirlines = [
  {
    name: "aeroflot",
    partner: true,
    listName: {
      ru: {
        name: "Аэрофлот",
      },
      uz: {
        name: "Aeroflot",
      },
      en: {
        name: "Aeroflot",
      },
    },
    imageUrl: "/images/AK/SU.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/SU.png",
  },
  {
    name: "uzairways",
    partner: true,
    listName: {
      ru: {
        name: "Uzbekistan Airways",
      },
      uz: {
        name: "O'zbekiston Havo Yo'llari",
      },
      en: {
        name: "Uzbekistan Airways",
      },
    },
    imageUrl: "/images/AK/HY.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/HY.png",
  },
  {
    name: "turkish",
    partner: true,
    listName: {
      ru: {
        name: "Турецкие Авиалинии",
      },
      uz: {
        name: "Turk Havo Yo'llari",
      },
      en: {
        name: "Turkish Airlines",
      },
    },
    imageUrl: "/images/AK/TK.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/TK.png",
  },
  {
    name: "belavia",
    partner: true,
    listName: {
      ru: {
        name: "Белавиа",
      },
      uz: {
        name: "Belavia",
      },
      en: {
        name: "Belavia",
      },
    },
    imageUrl: "/images/AK/B2.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/B2.png",
  },
  {
    name: "ural",
    partner: true,
    listName: {
      ru: {
        name: "Уральские Авиалинии",
      },
      uz: {
        name: "Ural Havo Yo'llari",
      },
      en: {
        name: "Ural Airlines",
      },
    },
    imageUrl: "/images/AK/U6.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/U6.png",
  },
  {
    name: "s7",
    partner: true,
    listName: {
      ru: {
        name: "S7 Airlines",
      },
      uz: {
        name: "S7 Havo Yo'llari",
      },
      en: {
        name: "S7 Airlines",
      },
    },
    imageUrl: "/images/AK/S7.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/S7.png",
  },
  {
    name: "qanotsharq",
    partner: true,
    listName: {
      ru: {
        name: "Qanot Sharq",
      },
      uz: {
        name: "Qanot Sharq",
      },
      en: {
        name: "Qanot Sharq",
      },
    },
    imageUrl: "/images/AK/HH.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/HH.png",
  },
  {
    name: "apg",
    partner: true,
    listName: {
      ru: {
        name: "APG Airlines",
      },
      uz: {
        name: "APG Havo Yo'llari",
      },
      en: {
        name: "APG Airlines",
      },
    },
    imageUrl: "/images/AK/GP.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/GP.png",
  },
  {
    name: "pobeda",
    partner: true,
    listName: {
      ru: {
        name: "Победа",
      },
      uz: {
        name: "Pobeda Hava Yo'llari",
      },
      en: {
        name: "Pobeda Airlines",
      },
    },
    imageUrl: "/images/AK/DP.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/DP.png",
  },
  {
    name: "airastana",
    partner: true,
    listName: {
      ru: {
        name: "Air Astana",
      },
      uz: {
        name: "Air Astana",
      },
      en: {
        name: "Air Astana",
      },
    },
    imageUrl: "/images/AK/KC.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/KC.png",
  },
  {
    name: "flyaristan",
    partner: true,
    listName: {
      ru: {
        name: "Fly Aristan",
      },
      uz: {
        name: "Fly Aristan",
      },
      en: {
        name: "Fly Aristan",
      },
    },
    imageUrl: "/images/AK/FS.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/FS.png",
  },
  {
    name: "aircairo",
    partner: false,
    listName: {
      ru: {
        name: "Air Cairo",
      },
      uz: {
        name: "Air Cairo",
      },
      en: {
        name: "Air Cairo",
      },
    },
    imageUrl: "/images/AK/SM.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/SM.png",
  },
  {
    name: "airchina",
    partner: false,
    listName: {
      ru: {
        name: "Air China",
      },
      uz: {
        name: "Air China",
      },
      en: {
        name: "Air China",
      },
    },
    imageUrl: "/images/AK/CA.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/CA.png",
  },
  {
    name: "airmanas",
    partner: false,
    listName: {
      ru: {
        name: "Air Manas",
      },
      uz: {
        name: "Air Manasa",
      },
      en: {
        name: "Air Manas",
      },
    },
    imageUrl: "/images/AK/ZM.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/ZM.png",
  },
  {
    name: "asianaAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Asiana Airlines",
      },
      uz: {
        name: "Asiana Havo Yo'llari",
      },
      en: {
        name: "Asiana Airlines",
      },
    },
    imageUrl: "/images/AK/OZ.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/OZ.png",
  },
  {
    name: "aviaTraffic",
    partner: false,
    listName: {
      ru: {
        name: "Avia Traffic",
      },
      uz: {
        name: "Avia Traffic",
      },
      en: {
        name: "Avia Traffic",
      },
    },
    imageUrl: "/images/AK/AT.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/AT.png",
  },
  {
    name: "azal",
    partner: false,
    listName: {
      ru: {
        name: "Azal",
      },
      uz: {
        name: "Azal",
      },
      en: {
        name: "Azal",
      },
    },
    imageUrl: "/images/AK/J2.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/J2.png",
  },
  {
    name: "azimuth",
    partner: false,
    listName: {
      ru: {
        name: "Азимут",
      },
      uz: {
        name: "Azimut",
      },
      en: {
        name: "Azimuth",
      },
    },
    imageUrl: "/images/AK/A4.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/A4.png",
  },
  {
    name: "azurair",
    partner: false,
    listName: {
      ru: {
        name: "Azur Air",
      },
      uz: {
        name: "Azur Air",
      },
      en: {
        name: "Azur Air",
      },
    },
    imageUrl: "/images/AK/ZF.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/ZF.png",
  },
];

import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSearchBlockCompact } from "../../store/appConfigsSlice";

import i18next from "i18next";
import { useParams } from "react-router-dom";
import { SeoTags } from "../../components";
import { PartnersInfoComponent } from "../../components/info/PartnersComponent/PartnersInfoComponent";
import { info } from "../../info/airlinesInfo/airlineArticleData";

const PartnersInfoPage = () => {
  const { language } = i18next;
  const { airline } = useParams();
  const airlineInfo = info[language][airline];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchBlockCompact(true));
  }, []);

  return (
    <>
      <SeoTags pageKey={airlineInfo.pageKey} />
      <PartnersInfoComponent limit={10} />
    </>
  );
};

export default withTranslation()(PartnersInfoPage);

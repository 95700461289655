import i18next from "i18next";
import { Link } from "react-router-dom";
import Card from "../../../components/ui/Card";
import { allAirlines } from "../../../info/airlinesInfo/allAirlines";
import { Cards, Title, Wrapper } from "./style";

export const AirlinesList = ({ limit = 5 }) => {
  const { t, language } = i18next;

  return (
    <Wrapper>
      <Title>{t("airlines")}</Title>
      <Cards>
        {allAirlines.map((item, index) => {
          const destination = {
            pathname: `${item.name}`,
            state: { ...item.name },
          };
          return (
            <Link key={index} to={destination}>
              <Card imageSrc={item.imageUrl} title={item.listName[language].name} />
            </Link>
          );
        })}
      </Cards>
    </Wrapper>
  );
};

import { Title, Wrapper } from "./style";

import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import i18next from "i18next";
import { allAirlines } from "../../../../info/airlinesInfo/allAirlines";

export const PartnersSwiperComponent = () => {
  const { t, language } = i18next;

  return (
    <Wrapper>
      <Title>{t("partners")}</Title>
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={1}
        slidesPerView={3}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          830: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1040: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1530: {
            slidesPerView: 4,
            spaceBetween: 60,
          },
        }}
        draggable={true}
        pagination={{ clickable: true }}
        ssMode={true}
        mousewheel={true}
        keyboard={true}
        className={"swiper_container"}
      >
        {allAirlines.filter(item => item.partner === true).map(item => {
          return (
            <SwiperSlide key={item.name} className={"swiperSlide"}>
              <Link to={`airlines/${item.name}`}>
                <img src={item.imageUrl} alt={item.listName[language].name} />
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Wrapper>
  );
};

import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSearchBlockCompact } from "../../store/appConfigsSlice";

import { SeoTags } from "../../components";
import { AirlinesList } from "../../components/info/AllAirlinesComponent";

const AirlinesListPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchBlockCompact(true));
  }, []);

  return (
    <>
      <SeoTags pageKey="airlines" />
      <AirlinesList />
    </>
  );
};

export default withTranslation()(AirlinesListPage);

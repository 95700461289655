const metaDescriptions = {
  ru: {
    homePage:
      "Добро пожаловать на сайт BiletBor.me: это лучшее место для бронирования авиабилетов без лишних хлопот. Изучите нашу удобную платформу для планирования путешествий, получения выгодных предложений и индивидуальных рекомендаций. Присоединяйтесь к миллионам довольных путешественников и начните свое следующее приключение с BiletBor.me уже сегодня!",
    aeroflotInfo:
      "Аэрофлот — одна из крупнейших авиакомпаний России. Узнайте о расписании рейсов из Узбекистана, aviabilet narxlari, багаже и флоте, а также о том, как купить дешевые авиабилеты.",
    airastanaInfo: "Air Astana — национальная авиакомпания Казахстана, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    aircairoInfo: "Air Cairo — египетская авиакомпания, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте, расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    airchinaInfo: "Air China — одна из ведущих авиакомпаний Китая, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    airmanasInfo: "Air Manas — киргизская авиакомпания, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    asianaAirlinesInfo: "Asiana Airlines — одна из ведущих авиакомпаний Республики Корея, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    aviaTrafficInfo: "Avia Traffic Company — киргизская авиакомпания, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    azalInfo: "AZAL — национальная авиакомпания Азербайджана, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    azimuthInfo: "Azimuth — российская авиакомпания, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    azurairInfo: "Azur Air — российская авиакомпания, выполняющая рейсы из Узбекистана. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    belaviaInfo: "Belavia — национальная авиакомпания Беларуси, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    uzairways: "Узнайте о Uzbekistan Airways: расписание рейсов, покупка авиабилетов, флот и маршруты. Актуальные цены и информация о перелетах.",
    s7Info: "Узнайте о S7 Airlines: покупка авиабилетов, маршруты, флот и расписание рейсов из Узбекистана. Бронируйте билеты онлайн.",
    turkishInfo: "Узнайте о Turkish Airlines: покупка авиабилетов, маршруты, флот и расписание рейсов из Узбекистана. Бронируйте билеты онлайн.",
    qanotsharqInfo: "Официальная информация о Qanot Sharq – узбекской авиакомпании. Узнайте об истории, флоте и расписании рейсов. Бронируйте дешёвые авиабилеты на biletbor.me.",
    uralInfo: "Узнайте о Ural Airlines: покупка авиабилетов, маршруты, флот и расписание рейсов из Узбекистана. Бронируйте билеты онлайн.",
  },
  en: {
    homePage:
      "Welcome to BiletBor.me: Your premier destination for hassle-free flight bookings. Explore our user-friendly platform for seamless travel planning, unbeatable deals, and personalized recommendations. Join millions of satisfied travelers and start your next adventure with BiletBor.me today!",
    aeroflotInfo:
      "Аэрофлот — одна из крупнейших авиакомпаний России. Узнайте о расписании рейсов из Узбекистана, aviabilet narxlari, багаже и флоте, а также о том, как купить дешевые авиабилеты.",
  },
  uz: {
    homePage:
      "BiletBor.me veb-saytiga xush kelibsiz: bu hech qanday muammosiz aviachiptalarni bron qilish uchun eng yaxshi joy. Sayohatni rejalashtirish, ajoyib takliflar va moslashtirilgan tavsiyalar uchun foydalanish uchun qulay platformamiz bilan tanishing. Millionlab mamnun sayohatchilar qatoriga qo'shiling va bugun BiletBor.me bilan keyingi sarguzashtingizni boshlang!",
    aeroflotInfo:
      "Аэрофлот — одна из крупнейших авиакомпаний России. Узнайте о расписании рейсов из Узбекистана, aviabilet narxlari, багаже и флоте, а также о том, как купить дешевые авиабилеты.",
    uzairways: "Узнайте о Uzbekistan Airways: расписание рейсов, покупка авиабилетов, флот и маршруты. Актуальные цены и информация о перелетах.",
    s7Info: "S7 Airlines reys jadvali, aviabiletlar va marshrutlar haqida batafsil ma’lumot. O‘zbekistondan reyslar va onlayn bron qilish.",
    turkishInfo: "Turkish Airlines reys jadvali, aviabiletlar va marshrutlar haqida batafsil ma’lumot. O‘zbekistondan reyslar va onlayn bron qilish.",
    qanotsharqInfo: "Rasmiy ma’lumotlar: Qanot Sharq aviakompaniyasi haqida bilib oling. Tarix, samolyotlar va reyslar jadvali. Arzon aviabiletlarni biletbor.me orqali band qiling.",
    uralInfo: "Ural Airlines reys jadvali, aviabiletlar va marshrutlar haqida batafsil ma’lumot. O‘zbekistondan reyslar va onlayn bron qilish.",
  },
};

export default metaDescriptions;

import HeaderLogoComponent from "../HeaderLogoComponent";
import HeaderNavigationComponent from "../HeaderNavigationComponent";

import { StyledContainer, Wrapper } from "./style";
import {Link} from "react-router-dom";
import i18next from "i18next";

const HeaderComponent = () => {
    const { t } = i18next

    return (
    <Wrapper>
      <StyledContainer>
          <div>
            <HeaderLogoComponent />
            <Link to="/scoreboard">{t("scoreborad")}</Link>
          </div>
        <HeaderNavigationComponent />
      </StyledContainer>
    </Wrapper>
  );
};

export default HeaderComponent;

import i18next from "i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { info } from "../../../../info/airlinesInfo/airlineArticleData";
import { allAirlines } from "../../../../info/airlinesInfo/allAirlines";
import { Card, Content, Image, Lists, MainInformation, OtherArticles, Wrapper } from "./style";

export const PartnersInfoComponent = ({ limit }) => {
  const { t, language } = i18next;
  const { airline } = useParams();
  const airlineInfo = info[language][airline];
  const mainContent = airlineInfo.generalInfo;
  const cardContent = airlineInfo.origin;
  const navigate = useNavigate();

  if (!airlineInfo) {
    navigate("/");
  }

  return (
    <Wrapper>
      <Content>
        <div>
          <MainInformation>
            {mainContent.map((item, index) => {
              return <div key={index} dangerouslySetInnerHTML={{ __html: `${item.content}` }} />;
            })}
          </MainInformation>
          <Card>
            <Image>
              <img src={airlineInfo.imageUrl} alt={airlineInfo.name} width={250} />
            </Image>
            {cardContent.map((item, index) => (
              <div key={index} dangerouslySetInnerHTML={{ __html: `${item.content}` }} />
            ))}
          </Card>
        </div>
        <h3>{t("inner_links")}</h3>
        <OtherArticles>
          <Lists>
            {allAirlines
              .sort(() => Math.random() - 0.5)
              .slice(0, limit)
              .map(item => {
                if (item.name !== airline) {
                  return (
                    <li>
                      <img src={item.smImageUrl} alt={item.name} />
                      <Link key={item.name} to={`/airlines/${item.name}`}>
                        <span>{item.listName[language].name}</span>
                      </Link>
                    </li>
                  );
                }
              })}
          </Lists>
        </OtherArticles>
      </Content>
    </Wrapper>
  );
};

import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "370px",
  height: "100%",
  maxHeight: "230px",
  padding: "25px",

  backgroundColor: "#fff",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",

  position: "relative",
  overflow: "hidden",

  [theme.down("md")]: {
    maxWidth: "340px",
  },
}));

export const CardDesc = styled("div")(({ theme }) => ({
  marginTop: "20px",
}));

export const Img = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "40px",

  "& img": {
    width: "100%",
    maxWidth: "180px",
    height: "auto",
  },
}));

export const Title = styled("div")(({ theme }) => ({
  padding: "10px 0",
  fontSize: 13,

  [theme.down("sm")]: {
    fontSize: "10px",
  },
}));

import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { LayoutComponent } from "../components";

import {
    AirlinesListPage,
    AuthPage,
    BalancePage,
    BookingPage,
    ContactsPage,
    ForgotPasswordPage,
    HomePage,
    InfoPaymentPage,
    OrderPage,
    OrdersPage,
    PartnersInfoPage,
    PrivacyPolicyPage,
    PublicOfferPage,
    RegistrationPage,
    ResetPasswordPage,
    ResultPage,
    RulesOfUsePage, ScoreboardPage,
    SuppliersPage,
    TransactionsPage,
} from "../pages";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutComponent />}>
      <Route index element={<HomePage />} />
      <Route path="/result/:searchId" element={<ResultPage />} />
      <Route path="/booking" element={<BookingPage />} />
      <Route path="/order/:orderId/:sessionId" element={<OrderPage />} />
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/user/balance" element={<BalancePage />} />
      <Route path="/payment-info" element={<InfoPaymentPage />} />
      <Route path="/privacy-policy-info" element={<PrivacyPolicyPage />} />
      <Route path="/public-offer-info" element={<PublicOfferPage />} />
      <Route path="/rules-of-use-info" element={<RulesOfUsePage />} />
      <Route path="/transactions" element={<TransactionsPage />} />
      <Route path="/contacts" element={<ContactsPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/partners" element={<SuppliersPage />} />
      <Route path="/airlines" element={<AirlinesListPage />} />
      <Route path="/airlines/:airline" element={<PartnersInfoPage />} />
      <Route path="/scoreboard" element={<ScoreboardPage />} />
    </Route>
  )
);

export default routes;

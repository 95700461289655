const metaOgObject = {
  ru: {
    homePage: {
      title: "Онлайн-касса BiletBor.me",
      type: "website",
      url: "https://biletbor.me/",
      image: "https://biletbor.me/images/background.png",
      description:
        "Наша удобная платформа делает бронирование авиабилетов простым делом. Найдите идеальный маршрут, получите предложение cashback и завершите покупку за считанные минуты.",
      site_name: "BiletBor.me",
    },
    cashbackPage: {
      title: "Акции - Кэшбэк BiletBor.me",
      type: "website",
      url: "https://biletbor.me/cashback-info",
      image: "https://biletbor.me/images/seo/cashback.jpg",
      description:
        "Надоело просто копить мили, которые вы, возможно, никогда не используете? Зарабатывайте реальные деньги на каждом рейсе, который вы бронируете у нас.",
      site_name: "BiletBor.me Кэшбэк",
    },
  },
  en: {
    homePage: {
      title: "Online ticketing BiletBor.me",
      type: "website",
      url: "https://biletbor.me/",
      image: "https://biletbor.me/images/background.png",
      description:
        "Our user-friendly platform makes booking flights a breeze. Find the perfect itinerary, secure your cashback offer, and complete your purchase in minutes.",
      site_name: "BiletBor.me",
    },
    cashbackPage: {
      title: "Deals - Cashback BiletBor.me",
      type: "website",
      url: "https://biletbor.me/cashback-info",
      image: "https://biletbor.me/images/seo/cashback.jpg",
      description:
        "Tired of just racking up miles you might never use? Earn real cash back on every flight you book with us.",
      site_name: "BiletBor.me Cashback",
    },
  },
  uz: {
    homePage: {
      title: "Onlayn-kassa BiletBor.me",
      type: "website",
      url: "https://biletbor.me/",
      image: "https://biletbor.me/images/background.png",
      description:
        "Bizning qulay platformamiz parvozlarni bron qilishni osonlashtiradi. Ideal marshrutingizni toping, cashback taklifini oling va xaridingizni bir necha daqiqada yakunlang.",
      site_name: "BiletBor.me",
    },
    cashbackPage: {
      title: "Aksiyalar - Cashback BiletBor.me",
      type: "website",
      url: "https://biletbor.me/cashback-info",
      image: "https://biletbor.me/images/seo/cashback.jpg",
      description:
        "Hech qachon foydalana olmaydigan millarni yig'ishdan charchadingizmi? Biz bilan bron qilgan har bir reysda haqiqiy pul ishlang.",
      site_name: "BiletBor.me Cashback",
    },
  },
};

export default metaOgObject;
